export default `
*,
*::before,
*::after {
  box-sizing: border-box;
}

.shiny-data-grid {
  --shiny-datagrid-font-size: 0.9em;
  --shiny-datagrid-padding-x: 0.5em;
  --shiny-datagrid-padding-y: 0.3em;
  --shiny-datagrid-padding: var(--shiny-datagrid-padding-y)
    var(--shiny-datagrid-padding-x);
  --shiny-datagrid-grid-header-bgcolor: var(--bs-light, #eee);
  --shiny-datagrid-grid-header-gridlines-color: var(--bs-border-color, #ccc);
  --shiny-datagrid-grid-header-gridlines-style: solid;
  --shiny-datagrid-grid-gridlines-color: var(--bs-border-color, #ccc);
  --shiny-datagrid-grid-gridlines-style: solid;
  --shiny-datagrid-table-header-bottom-border: 1px solid;
  --shiny-datagrid-table-top-border: 1px solid;
  --shiny-datagrid-table-bottom-border: 1px solid;
  --shiny-datagrid-grid-body-hover-bgcolor: var(
    --shiny-datagrid-grid-header-bgcolor
  );
  --shiny-datagrid-grid-body-selected-bgcolor: #b4d5fe;
  --shiny-datagrid-grid-body-selected-color: var(--bs-dark);
}

.shiny-data-grid svg.sort-arrow {
  display: inline-block;
  width: 0.85em;
  height: 0.85em;
  margin-bottom: 0.15em;
}

.shiny-data-grid {
  max-width: 100%;
}
.shiny-data-grid > table {
  border-collapse: separate;
  border-spacing: 0;
}
.shiny-data-grid > table > thead {
  position: sticky;
  top: 0;
}
.shiny-data-grid > table > thead > tr > th {
  text-align: left;
  white-space: nowrap;
}
.shiny-data-grid > table > thead > tr > th:focus-visible {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}
.shiny-data-grid > table.filtering > thead > tr:nth-last-child(2) > th {
  border-bottom: none;
}
.shiny-data-grid > table.filtering > thead > tr.filters > th {
  font-weight: unset;
  padding-top: 0;
  /* Slight boost to bottom padding */
  padding-bottom: var(--shiny-datagrid-padding-x);
}
.shiny-data-grid > table.filtering > thead > tr.filters > th > input {
  width: 100%;
}

.shiny-data-grid.shiny-data-grid-table {
  border-top: var(--shiny-datagrid-table-top-border);
}
.shiny-data-grid.shiny-data-grid-table.scrolling {
  border-bottom: var(--shiny-datagrid-table-bottom-border);
}
.shiny-data-grid.shiny-data-grid-table > table > thead > tr:last-child > th {
  border-bottom: var(--shiny-datagrid-table-header-bottom-border);
}
.shiny-data-grid.shiny-data-grid-table > table > tbody > tr[aria-selected=true] {
  --shiny-datagrid-grid-gridlines-color: var(
    --shiny-datagrid-grid-body-selected-bgcolor
  );
  background-color: var(--shiny-datagrid-grid-body-selected-bgcolor);
  color: var(--shiny-datagrid-grid-body-selected-color);
}

/*
 *
 * # GRID STYLES
 *
 */
.shiny-data-grid.shiny-data-grid-grid > table {
  font-size: var(--shiny-datagrid-font-size);
}
.shiny-data-grid.shiny-data-grid-grid > table > thead > tr > th,
.shiny-data-grid.shiny-data-grid-grid > table > thead > tr > td {
  background-color: var(--shiny-datagrid-grid-header-bgcolor);
  padding: var(--shiny-datagrid-padding);
}
.shiny-data-grid.shiny-data-grid-grid > table > tbody > tr:focus-visible {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}
.shiny-data-grid.shiny-data-grid-grid > table > tbody > tr:hover {
  --shiny-datagrid-grid-gridlines-color: inherit;
  background-color: var(--shiny-datagrid-grid-body-hover-bgcolor);
}
.shiny-data-grid.shiny-data-grid-grid > table > tbody > tr[aria-selected=true] {
  --shiny-datagrid-grid-gridlines-color: var(
    --shiny-datagrid-grid-body-selected-bgcolor
  );
  background-color: var(--shiny-datagrid-grid-body-selected-bgcolor);
  color: var(--shiny-datagrid-grid-body-selected-color);
}
.shiny-data-grid.shiny-data-grid-grid > table > tbody > tr > td {
  padding: var(--shiny-datagrid-padding);
}

/* ## Grid borders */
.shiny-data-grid.shiny-data-grid-grid > table {
  border-collapse: separate;
}
.shiny-data-grid.shiny-data-grid-grid > table > thead > tr:first-child > th {
  border-top-style: var(--shiny-datagrid-grid-gridlines-style);
}
.shiny-data-grid.shiny-data-grid-grid > table > thead > tr > th {
  border: 1px var(--shiny-datagrid-grid-gridlines-style) var(--shiny-datagrid-grid-header-gridlines-color);
  border-top-style: none;
  border-left-style: none;
}
.shiny-data-grid.shiny-data-grid-grid > table > thead > tr > th:first-child {
  border-left-style: var(--shiny-datagrid-grid-gridlines-style);
}
.shiny-data-grid.shiny-data-grid-grid > table > tbody > tr > td {
  border: 1px var(--shiny-datagrid-grid-gridlines-style) var(--shiny-datagrid-grid-gridlines-color);
  border-top-style: none;
  border-left-style: none;
}
.shiny-data-grid.shiny-data-grid-grid > table > tbody > tr > td:first-child {
  border-left-style: var(--shiny-datagrid-grid-gridlines-style);
}
.shiny-data-grid.shiny-data-grid-grid.scrolling {
  border: var(--shiny-datagrid-grid-gridlines-style) 1px var(--shiny-datagrid-grid-header-gridlines-color);
}
.shiny-data-grid.shiny-data-grid-grid.scrolling > table > thead > tr:first-child > th {
  border-top-style: none;
}
.shiny-data-grid.shiny-data-grid-grid.scrolling > table > tbody > tr:last-child > td {
  border-bottom-style: none;
}
.shiny-data-grid.shiny-data-grid-grid.scrolling > table > thead > tr > th:first-child,
.shiny-data-grid.shiny-data-grid-grid.scrolling > table > tbody > tr > td:first-child {
  border-left-style: none;
}
.shiny-data-grid.shiny-data-grid-grid.scrolling > table > thead > tr > th:last-child,
.shiny-data-grid.shiny-data-grid-grid.scrolling > table > tbody > tr > td:last-child {
  border-right-style: none;
}`;
